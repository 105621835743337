<template>
  <div>
    <v-form ref="m_formElement" :disabled="bSubmitting">
      <v-row>
        <v-col cols="12" :class="`col-${strColBreakpoint}-${iColBreakpoint}`">
          <div class="kda-ts-16pt nunito wt-semibold mb-2">{{ m_arrInputLabels[0] }}</div>
          <base-text-field
            outlined
            :name="m_arrInputLabels[0]"
            required
            color="black"
            @change="changeName($event)"
          />

          <div class="kda-ts-16pt nunito wt-semibold mb-2">{{ m_arrInputLabels[1] }}</div>
          <base-text-field
            outlined
            :name="m_arrInputLabels[1]"
            required
            color="black"
            @change="changeSubject($event)"
          />

          <div class="kda-ts-16pt nunito wt-semibold mb-2">{{ m_arrInputLabels[3] }}</div>
          <base-text-field
            outlined
            :name="m_arrInputLabels[3]"
            email
            required
            color="black"
            @change="changeEmail($event)"
          />
        </v-col>
      </v-row>

      <div class="kda-ts-16pt nunito wt-semibold mb-2">{{ m_arrInputLabels[4] }}</div>
      <base-textarea
        outlined
        class="mb-6"
        :name="m_arrInputLabels[4]"
        required
        color="black"
        @change="changeMessage($event)"
      />
    </v-form>
    <div :class="`${g_bLowerBr && 'text-center'}`">
      <v-btn
        color="main-r-400"
        class="px-12"
        style="color: white; text-transform: capitalize"
        :disabled="bSubmitting"
        @click="submitEmail()"
      >
        {{ strButtonLabel }}
      </v-btn>
    </div>

    <k-d-a-submitted-modal
      v-model="g_bContactUsEmailSubmitted"
      :str-title="m_objSubmittedMessage.strTitle"
      :str-text="m_objSubmittedMessage.strText"
      text-color="black"
      @input="toggleModal($event)"
    />
  </div>
</template>

<script>
import APIRequests from '@/mixins/api-requests';
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAConsultationsForm',

  components: {
    KDASubmittedModal: () => import('@/components/KDASubmittedModal')
  },

  mixins: [APIRequests, BaseBreakpoint],

  props: {
    strButtonLabel: {
      type: String,
      default: 'Kirim'
    },
    strColBreakpoint: {
      type: String,
      default: 'md'
    },
    iColBreakpoint: {
      type: [Number, String],
      default: 5
    },
    bSubmitting: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    m_objSubmittedMessage: {
      strTitle: 'Thank you for contacting us',
      strText: 'We will send a reply within 2-3 business days.'
    },
    m_strAnonymousName: '',
    m_strAnonymousEmail: '',
    m_strAnonymousSubject: '',
    m_strAnonymousMessage: ''
  }),

  computed: {
    m_arrInputLabels() {
      const result = [];

      for (let i = 1; i <= 5; i++) {
        result.push(this.$vuetify.lang.t(`$vuetify.kda.consult.form.input${i}`));
      }

      return result;
    }
  },

  methods: {
    changeName(anonymousName) {
      if (anonymousName) {
        this.m_strAnonymousName = anonymousName;
      }
    },
    changeEmail(anonymousEmail) {
      if (anonymousEmail) {
        this.m_strAnonymousEmail = anonymousEmail;
      }
    },
    changeSubject(anonymousSubject) {
      if (anonymousSubject) {
        this.m_strAnonymousSubject = anonymousSubject;
      }
    },
    changeMessage(anonymousMessage) {
      if (anonymousMessage) {
        this.m_strAnonymousMessage = anonymousMessage;
      }
    },
    submitEmail() {
      window.gtag('event', 'click', {
        'event_label': 'Send'
      });

      const isValid = this.$refs.m_formElement.validate();
      if (isValid) {
        this.postEmail(
          this.m_strAnonymousName,
          this.m_strAnonymousEmail,
          this.m_strAnonymousSubject,
          this.m_strAnonymousMessage
        );
      }
    },
    toggleModal(bShow) {
      this.$router.go();
    }
  }
};
</script>
